/* @flow */
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import t from 'counterpart';
import { Banner } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';

import Link from '../link';
import { track } from '../../../helpers/tools';
import getBanner from '../../../helpers/getBanner';
import { GET_LICENSES } from '../../../graphql/queries/license';

const Code = styled.span`
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const StyledBanner = styled(Banner)`
  position: fixed;
  bottom: ${({ theme }) => theme.space[2]}px;
  right: ${({ theme }) => theme.space[2]}px;
  z-index: 15;
  left: ${({ isAuthenticated, theme, isMobile }) =>
    isAuthenticated && !isMobile
      ? `calc(${theme.dimensions.sideNavWidth} + ${theme.space[2]}px)`
      : `${theme.space[2]}px`};
  a {
    color: ${({ theme }) => theme.colors.aqua50};
  }
`;

type PromoBannerProps = {
  defaultBannerDismissed: Date,
  isAuthenticated: boolean,
  isMobile: boolean,
  isSelfServe: boolean,
  setSessionField: Function,
  specialBannerDismissed: Date,
} & WithRouterProps;

const PromoBanner = ({
  defaultBannerDismissed,
  getPath,
  isAuthenticated,
  isMobile,
  isSelfServe,
  query,
  setSessionField,
  specialBannerDismissed,
}: PromoBannerProps) => {
  const { data = {} } = useQuery(GET_LICENSES);
  const { licenses } = data;
  const [copied, setCopied] = useState(false);

  const banner = getBanner({
    defaultBannerDismissed,
    specialBannerDismissed,
    licenses,
    isAuthenticated,
    isSelfServe,
    query,
    path: getPath(),
  });

  if (!banner.active) return null;

  const handleClick = () => {
    if (banner.isSpecialBanner) {
      navigator.clipboard.writeText(t('specialBanner.code')).then(
        () => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2500);
        },
        () =>
          console.log("Copy to clipboard didn't work, browser not supported?")
      );

      track({
        eventName: 'promo_bannerClick',
        eventAction: 'copy_to_clipboard',
        eventType: 'click_inbound',
        eventLabel: t('specialBanner.code'),
        eventPosition: '',
      });
    }
  };

  const handleClose = () => {
    setSessionField({
      name: banner.sessionField,
      value: new Date(),
    });
  };

  const Content = () => {
    if (banner.isSpecialBanner && copied) {
      return <span>{t('specialBanner.copied')}</span>;
    } else {
      return (
        <>
          {banner.isSpecialBanner ? (
            <>
              <span>{t('specialBanner.preCodeText')}</span>
              <Code onClick={handleClick}>{t('specialBanner.code')}</Code>
              <span>{t('specialBanner.postCodeText')}</span>
            </>
          ) : (
            <>
              <span>{t('defaultBanner.text')} </span>
              <Link href={banner.href}>{t('defaultBanner.linkText')}</Link>
            </>
          )}
        </>
      );
    }
  };

  return (
    <ThemeProvider>
      <StyledBanner
        isMobile={isMobile}
        isAuthenticated={isAuthenticated}
        onClose={handleClose}
        dataTestId="banner_close">
        <Content />
      </StyledBanner>
    </ThemeProvider>
  );
};

export default PromoBanner;
