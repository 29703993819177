/* @flow */
/* eslint-env browser */
import { Component } from 'react';
import PhotoGridRow from '../../shared/photoGridRow/';

import {
  isArrayUnequal,
  getPositionOffsetInJustifiedRow,
} from '../../../../../helpers/tools';

type Props = {
  hideLastRow: boolean,
  stretchLastRow: boolean,
  openOverlay: Function,

  // whether to show photocard timestamp
  noTimestamp: boolean,

  showUser: boolean,
  rows: $ReadOnlyArray<$ReadOnlyArray<EyeEmAssetId>>,

  contextPaginatableMetadata: ContextPaginatableMetadataState,
  additionalContextPaginatableMetadata?: AdditionalContextPaginatableMetadata,
  translationPrefix?: string,
  deviceType: string,

  resourceType: string,
  actionBar: string,

  className: string,
  eventPosition: string,
  shouldUnsetContextPaginatableMetadata: boolean,
  positionOffset: number,
  shouldShowCaptions: boolean,
  isMobile: boolean,
};

class RowGrid extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    // Whats missing here?
    return nextProps.rows.some(
      (row, index) =>
        nextProps.positionOffset !== this.props.positionOffset ||
        !this.props.rows[index] ||
        isArrayUnequal(nextProps.rows[index], this.props.rows[index])
    );
  }

  shouldLazyLoadPhoto = (rowIndex: number) => {
    // first rows are most likely in screen, we dont lazy load them, they should load right away
    const lazyRow = this.props.isMobile ? 2 : 4;
    return rowIndex > lazyRow;
  };

  getPhotoGridrow = (assets, rowIndex: number) => (
    <PhotoGridRow
      shouldUnsetContextPaginatableMetadata={
        this.props.shouldUnsetContextPaginatableMetadata
      }
      key={rowIndex}
      translationPrefix={this.props.translationPrefix}
      deviceType={this.props.deviceType}
      stretch={
        rowIndex !== this.props.rows.length - 1 || this.props.stretchLastRow
      }
      actionBar={this.props.actionBar}
      noTimestamp={this.props.noTimestamp}
      showUser={this.props.showUser}
      openOverlay={this.props.openOverlay}
      lastRowInGrid={rowIndex === this.props.rows.length - 1}
      eventPosition={this.props.eventPosition}
      contextPaginatableMetadata={this.props.contextPaginatableMetadata}
      shouldShowCaptions={this.props.shouldShowCaptions}
      shouldLazyLoadPhoto={this.shouldLazyLoadPhoto(rowIndex)}
      assets={assets}
      positionOffset={getPositionOffsetInJustifiedRow(
        this.props.rows,
        rowIndex,
        this.props.positionOffset
      )}
    />
  );

  render() {
    return (
      <div className={`lp_fullWidth ${this.props.className}`}>
        {this.props.rows
          .slice(0, this.props.hideLastRow ? -1 : this.props.rows.length)
          .map((assets, rowIndex) => this.getPhotoGridrow(assets, rowIndex))}
      </div>
    );
  }
}

export default RowGrid;
