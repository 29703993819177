/* @flow */
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import parseISO from 'date-fns/parseISO';

import {
  PRICEPOINT_TYPE_PACK,
  SESSION_FIELD_DEFAULT_BANNER_DISMISSED,
  SESSION_FIELD_SPECIAL_BANNER_DISMISSED,
} from '../constants/misc';

// Last requested special banner logic:
// https://jiraeyeem.atlassian.net/browse/MM-1957
// We can override date logic for testing by setting query string param testSpecialBanner=1

const inSpecialBannerPeriod = (query: Object) => {
  const now = new Date();

  // new Date(2021, 1, 4) is 2021/02/04 since months start with 0
  const startDate = new Date(2021, 10, 24);
  const endDate = new Date(2021, 11, 7);

  return (
    query?.testSpecialBanner === '1' ||
    (now.getTime() >= startDate.getTime() && now.getTime() < endDate.getTime())
  );
};

const showSpecialBanner = (
  specialBannerDismissed: Date,
  isAuthenticated: boolean,
  isSelfServe: boolean
) => {
  // Do not show for enterprise users or creators
  if (isAuthenticated && !isSelfServe) return false;

  // Allow users to dismiss for 20 minutes
  if (!specialBannerDismissed) return true;
  if (differenceInMinutes(new Date(), parseISO(specialBannerDismissed, 1)) > 20)
    return true;

  return false;
};

const getDefaultBannerHref = (path: string) => {
  const source = 'promo_banner_subscriptions';

  let location = '';
  if (path.indexOf('/search/') === 0) {
    location = 'search';
  } else if (path.indexOf('/u/') === 0) {
    location = 'user';
  } else if (path.indexOf('/a/') === 0) {
    location = 'album';
  } else if (path === '/collection') {
    location = 'purchaseHistory';
  } else if (path.indexOf('/lightboxes/') === 0) {
    location = 'lightbox';
  } else if (path.indexOf('/p/') === 0 || path.indexOf('/i/') === 0) {
    location = 'photoview';
  }

  return `/market/pricing?source=${source}&location=${location}`;
};

const showDefaultBanner = (
  defaultBannerDismissed: Date,
  licenses: LicensingOption[],
  isAuthenticated: boolean,
  isSelfServe: boolean
) => {
  if (!licenses) return false;

  // Allow users to dismiss for 30 days
  const hasDismissedMoreThenSetTimeAgo =
    differenceInCalendarDays(new Date(), parseISO(defaultBannerDismissed, 1)) >
    30;

  if (!isAuthenticated) {
    return !defaultBannerDismissed || hasDismissedMoreThenSetTimeAgo;
  }

  if (isSelfServe) {
    if (!defaultBannerDismissed) return true;

    const hasPack = licenses.some(
      (license: LicensingOption) =>
        license.pricePoints?.premium?.type === PRICEPOINT_TYPE_PACK ||
        license.pricePoints?.regular?.type === PRICEPOINT_TYPE_PACK
    );
    if (!hasPack && hasDismissedMoreThenSetTimeAgo) return true;
  }

  return false;
};

export type GetBannerArgs = {
  defaultBannerDismissed: Date,
  specialBannerDismissed: Date,
  licenses: LicensingOption[],
  isAuthenticated: boolean,
  isSelfServe: boolean,
  query: Object,
  path: string,
};

export type GetBannerReturn = {
  active: boolean,
  href: string | null,
  isSpecialBanner: boolean,
  sessionField: string,
};

const getBanner = ({
  defaultBannerDismissed,
  specialBannerDismissed,
  licenses,
  isAuthenticated,
  isSelfServe,
  query,
  path,
}: GetBannerArgs): GetBannerReturn => {
  const inSpecialPeriod = inSpecialBannerPeriod(query);

  if (
    inSpecialPeriod &&
    showSpecialBanner(specialBannerDismissed, isAuthenticated, isSelfServe)
  ) {
    return {
      active: true,
      href: null,
      isSpecialBanner: true,
      sessionField: SESSION_FIELD_SPECIAL_BANNER_DISMISSED,
    };
  }

  if (
    !inSpecialPeriod &&
    showDefaultBanner(
      defaultBannerDismissed,
      licenses,
      isAuthenticated,
      isSelfServe
    )
  ) {
    return {
      active: true,
      href: getDefaultBannerHref(path),
      isSpecialBanner: false,
      sessionField: SESSION_FIELD_DEFAULT_BANNER_DISMISSED,
    };
  }

  return {
    active: false,
    href: '',
    isSpecialBanner: false,
    sessionField: '',
  };
};

export default getBanner;
