/* @flow */
import { connect } from 'react-redux';

import { getDeviceType, getMaxDeviceWidth } from '../../../../../reducers/';

import PhotoGridRow from './photoGridRow.jsx';

export default connect((state, ownProps) => {
  const assets = ownProps.assets;
  return {
    deviceType: getDeviceType(state),
    maxDeviceWidth: getMaxDeviceWidth(state),
    assets,
    rowHash: assets?.map((asset) => asset.id).join(''),
    rowLightboxIdsHash: assets
      ?.map((asset) => asset.lightboxIds?.join(''))
      .join(''),
  };
})(PhotoGridRow);
