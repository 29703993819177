/* @flow */
import { Component } from 'react';
import styled from '@emotion/styled';

import PhotoCard from '../photoCard/';

import {
  LAST_ROW_HEIGHT,
  DEVICE_TYPE_PHONE,
  DEVICE_TYPE_TABLET,
  DEVICE_TYPE_DESKTOP,
} from '../../../../../constants/misc';

const StyledPhotoCard = styled(PhotoCard)`
  margin-top: 0;
  margin-right: ${({ marginRight }) => marginRight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-left: 0;
`;

const margins = {
  [DEVICE_TYPE_PHONE]: 2.5,
  [DEVICE_TYPE_TABLET]: 1,
  [DEVICE_TYPE_DESKTOP]: 0.6,
};

type Props = {
  // margins are dependent on devicetype
  deviceType: string,

  assets: $ReadOnlyArray<EyeEmPhoto> | $ReadOnlyArray<EyeEmIllustration>,

  maxDeviceWidth: number,

  stretch: boolean,

  // If it's the last row in a grid,
  // there's no bottom padding
  lastRowInGrid: boolean,
  eventPosition: string,
  translationPrefix: string,
  contextPaginatableMetadata: ContextPaginatableMetadataState,
  contentPID: PIDObject,
  additionalContextPaginatableMetadata: AdditionalContextPaginatableMetadata,

  feedbackWidget: boolean,
  actionBar: string,
  noTimestamp: boolean,
  showUser: boolean,
  openOverlay: Function,
  positionOffset?: number,
  shouldUnsetContextPaginatableMetadata: boolean,
  rowHash: string,
  rowLightboxIdsHash: string,
  shouldShowCaptions: boolean,
  shouldLazyLoadPhoto: boolean,
};

class PhotoGridRow extends Component<Props> {
  static defaultProps = {
    positionOffset: 0,
  };

  shouldComponentUpdate = (nextProps: Props) =>
    nextProps.lastRowInGrid !== this.props.lastRowInGrid ||
    nextProps.positionOffset !== this.props.positionOffset ||
    nextProps.rowHash !== this.props.rowHash ||
    nextProps.rowLightboxIdsHash !== this.props.rowLightboxIdsHash;

  getMargin = () => margins[this.props.deviceType];

  // one margin for every photo except the last one
  getSumOfMargins = () => (this.props.assets.length - 1) * this.getMargin();

  getNormWidths = () =>
    this.props.assets.map((asset) => asset.width * (100 / asset.height));

  getTotalNormalizedWidth = () =>
    this.getNormWidths().reduce((sum, el) => sum + el, 0);

  render() {
    return (
      <div style={{ display: 'flex' }}>
        {this.props.assets.map((asset, i) => {
          const percentage =
            (this.getNormWidths()[i] / this.getTotalNormalizedWidth()) *
            (100 - this.getSumOfMargins());
          const nonStretchedHeight = LAST_ROW_HEIGHT;
          const nonStretchedWidth =
            asset.width / (asset.height / nonStretchedHeight);

          return (
            <StyledPhotoCard
              style={{
                width: this.props.stretch
                  ? `${percentage}%`
                  : `${nonStretchedWidth}px`,
                maxWidth: this.props.stretch ? 'auto' : `${percentage}%`,
              }}
              marginRight={
                i === this.props.assets.length - 1 ? 0 : `${this.getMargin()}%`
              }
              marginBottom={
                this.props.lastRowInGrid ? 0 : `${this.getMargin()}%`
              }
              key={`photo-card-${asset.id}-${i}`}
              asset={asset}
              actionBar={this.props.actionBar}
              noTimestaphmp={this.props.noTimestamp}
              showUser={this.props.showUser}
              width={
                this.props.stretch
                  ? (this.props.maxDeviceWidth / 100) * percentage
                  : nonStretchedWidth
              }
              openOverlay={this.props.openOverlay}
              gridIndex={this.props.positionOffset + i}
              contextPaginatableMetadata={this.props.contextPaginatableMetadata}
              contentPID={this.props.contentPID}
              additionalContextPaginatableMetadata={
                this.props.additionalContextPaginatableMetadata
              }
              shouldShowCaptions={this.props.shouldShowCaptions}
              shouldLazyLoadPhoto={this.props.shouldLazyLoadPhoto}
            />
          );
        })}
      </div>
    );
  }
}

export default PhotoGridRow;
