/* @flow */
import { connect } from 'react-redux';

import {
  getDeviceType,
  getIsBuyer,
  getIsMobile,
} from '../../../../../reducers/';

import { JUSTIFIED_GRID_NORMALIZED_WIDTHS } from '../../../../../constants/misc';

import RowGrid from './rowGrid.jsx';

const getDifference = (num1, num2) => {
  if (num1 > num2) {
    return num1 - num2;
  }
  return num2 - num1;
};

const getRow = (assets, idealNormWidth) => {
  let count = 0;
  let totalNormalizedWidth = 0;
  let previousDifferenceFromIdeal;

  assets.every((asset) => {
    const normalizationRatio = 1 / asset.height;
    const normalizedWidth = asset.width * normalizationRatio;
    const currentDifferenceFromIdeal = getDifference(
      idealNormWidth,
      totalNormalizedWidth + normalizedWidth
    );

    if (
      !previousDifferenceFromIdeal ||
      currentDifferenceFromIdeal < previousDifferenceFromIdeal
    ) {
      totalNormalizedWidth += normalizedWidth;
      count += 1;
      previousDifferenceFromIdeal = currentDifferenceFromIdeal;
      return true;
    }

    return false;
  });

  return assets.slice(0, count);
};

const getJustifiedRows = (assets, normWidth, existingRows = []) => {
  // eslint-disable-next-line
  assets = assets.filter((asset) => asset); // remove empty items from array to avoid max callstack
  const row = getRow(assets, normWidth);

  const rows = [...existingRows, row];

  if (row.length === assets.length) {
    return rows;
  }

  return getJustifiedRows(assets.slice(row.length), normWidth, rows);
};

const mapStateToProps = (state, ownProps) => ({
  isMobile: getIsMobile(state),
  rows:
    ownProps.rows ||
    getJustifiedRows(
      ownProps.assets,
      JUSTIFIED_GRID_NORMALIZED_WIDTHS[
        getIsBuyer(state) ? 'buyer' : 'photographer'
      ][getDeviceType(state)]
    ),
});

export default connect(mapStateToProps)(RowGrid);
