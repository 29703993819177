/* @flow */
import { compose } from 'redux';
import { connect } from 'react-redux';

import withRouter from '../../../helpers/hoc/withRouter';
import { setSessionFieldThunk } from '../../../helpers/actionCreators/redux';

import {
  getIsAuthenticated,
  getIsBuyer,
  getIsEnterpriseCustomer,
  getIsMobile,
  getSessionField,
} from '../../../reducers/';

import {
  SESSION_FIELD_DEFAULT_BANNER_DISMISSED,
  SESSION_FIELD_SPECIAL_BANNER_DISMISSED,
} from '../../../constants/misc';

import PromoBanner from './promoBanner';

const mapStateToProps = (state) => ({
  defaultBannerDismissed: getSessionField(
    state,
    SESSION_FIELD_DEFAULT_BANNER_DISMISSED
  ),
  isAuthenticated: getIsAuthenticated(state),
  isMobile: getIsMobile(state),
  isSelfServe: getIsBuyer(state) && !getIsEnterpriseCustomer(state),
  specialBannerDismissed: getSessionField(
    state,
    SESSION_FIELD_SPECIAL_BANNER_DISMISSED
  ),
});

const mapDispatchToProps = (dispatch) => ({
  setSessionField: (payload) => dispatch(setSessionFieldThunk(payload)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PromoBanner);
